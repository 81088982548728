import consumer from "./consumer"

document.addEventListener("DOMContentLoaded", function() {
  const mainContainer = document.getElementById('user-container');
  const currentUserID = mainContainer.getAttribute('data-current-user-id');

consumer.subscriptions.create({ channel: "ImportChannel", user_id: currentUserID }, {

  connected() { },

  disconnected() { },

  received(data) {
    const progressBarsContainer = document.getElementById('progress-bars-container');

    // Create a unique ID for the progress bar container
    const progressBarContainerId = `progress-container-${data.random_string.replace(/\s/g, '-')}`;

    // Check if the progress bar container already exists
    let progressContainer = document.getElementById(progressBarContainerId);
    if (!progressContainer) {
      // Create HTML elements for progress bar
      progressContainer = document.createElement('div');
      progressContainer.id = progressBarContainerId;
      progressContainer.classList.add('progress-container');
      progressContainer.style.display = 'none';

      // Add progress bar HTML to the container
      progressContainer.innerHTML = `
      <div class="row">
        <div class="col-md-9">
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" role="progressbar" id="progress-bar-fill-${data.random_string.replace(/\s/g, '-')}"
                 aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"><span id="progress_value-${data.random_string.replace(/\s/g, '-')}">0</span>
            </div>
          </div>
        </div>
        <div class="col-md-3" id="fileNameDisplay">${data.csv_file_name}</div>
      </div>
      <div class="progress-text">
        <span id="rows_processed-${data.random_string.replace(/\s/g, '-')}">0</span> / <span id="total-number">${data.total_rows}</span>
      </div>
      <br>`;

      // Append the progress bar container to the main container
      progressBarsContainer.appendChild(progressContainer);
    }

    // Update progress bar values
    if (data.progress) {
      const progressBarFill = document.getElementById(`progress-bar-fill-${data.random_string.replace(/\s/g, '-')}`);
      const progressValue = document.getElementById(`progress_value-${data.random_string.replace(/\s/g, '-')}`);
      const progressProcessed = document.getElementById(`rows_processed-${data.random_string.replace(/\s/g, '-')}`);

      progressBarFill.style.width = `${data.progress}%`;
      progressValue.innerText = `${data.progress}%`;
      progressProcessed.innerText = data.rows_processed;

      // Show progress bar container
      progressContainer.style.display = 'block';
    } else {
      // Hide progress bar container if progress is not available
      progressContainer.style.display = 'none';
    }
  }

});
});