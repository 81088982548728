 import consumer from "./consumer"

  document.addEventListener("DOMContentLoaded", function() {
    const mainContainer = document.getElementById('user-container');
    const currentUserID = mainContainer.getAttribute('data-current-user-id');

    consumer.subscriptions.create({ channel: "UserNotificationsChannel", user_id: currentUserID }, {

      connected() { },

      disconnected() { },

      received(data) {
        const notificationsList = document.getElementById("user-notifications-list");

        // Clear existing notifications
        notificationsList.innerHTML = "";

        // Update notification count
        document.getElementById("notification-count").innerText = data.count;

        // Iterate over new notifications and append them to the list
        data.user_notifications.forEach(notification => {
          const listItem = document.createElement("li");
          listItem.id = `notification-${notification.id}`;
          listItem.className = notification.read ? "" : "unread";

          const link = document.createElement("a");
          link.href = "#";
          link.className = "d-flex";

          const imgDiv = document.createElement("div");
          imgDiv.className = "img mr-3";
          // Set the image based on notification type
          if (notification.notification_type === 'Invoice') {
            imgDiv.innerHTML = '<img src="/assets/ic-invoice2.svg" alt="Invoice Icon">';
          } else if (notification.notification_type === 'User') {
            imgDiv.innerHTML = '<img src="/assets/ic-user-loc2.svg" alt="User Icon">';
          } else if (['Purchase Order', 'Delivery Order'].includes(notification.notification_type)) {
            imgDiv.innerHTML = '<img src="/assets/ic-po2.svg" alt="PO Icon">';
          } else if (notification.notification_type === 'Early Payment Request') {
            imgDiv.innerHTML = '<img src="/assets/ic-epr2.svg" alt="EPR Icon">';
          } else if (notification.notification_type === 'Discount Rule') {
            imgDiv.innerHTML = '<img src="/assets/ic-discount2.svg" alt="Discount Icon">';
          } else if (notification.notification_type === 'Permission') {
            imgDiv.innerHTML = '<img src="/assets/ic-usermgt2.svg" alt="Permission Icon">';
          } else {
            imgDiv.innerHTML = '<i class="bi bi-bell-fill fs-4"></i>';
          }

          const textDiv = document.createElement("div");
          textDiv.className = "text";
          textDiv.innerHTML = `<strong>${notification.subject } <span style="color: #606060; margin-bottom: 0"> ${customTimeAgoInWords(notification.created_at)}</span></strong><div>${truncateString(notification.body, 43)}</div>`;

          link.appendChild(imgDiv);
          link.appendChild(textDiv);
          listItem.appendChild(link);
          notificationsList.appendChild(listItem);
        });
        // Add "View All" link after the list
        const viewAllLink = document.createElement("div");
        viewAllLink.className = "text-center m-0 p-0";
        viewAllLink.innerHTML = '<a href="/user_notifications">View All</a>';
        notificationsList.appendChild(viewAllLink);
      }
    });
  });

 function truncateString(str, maxLength, separator = '...') {
   if (str.length <= maxLength) return str;
   return str.slice(0, maxLength) + separator;
 }
 function customTimeAgoInWords(timestamp) {
   const now = new Date();
   const createdDate = new Date(timestamp);

   const seconds = Math.floor((now - createdDate) / 1000);
   let interval = Math.floor(seconds / 31536000);

   if (interval >= 1) {
     return interval + " years ago";
   }
   interval = Math.floor(seconds / 2592000);
   if (interval >= 1) {
     return interval + " months ago";
   }
   interval = Math.floor(seconds / 86400);
   if (interval >= 1) {
     return interval + " days ago";
   }
   interval = Math.floor(seconds / 3600);
   if (interval >= 1) {
     return interval + " hours ago";
   }
   interval = Math.floor(seconds / 60);
   if (interval >= 1) {
     return interval + " minutes ago";
   }
   return Math.floor(seconds) + " seconds ago";
 }